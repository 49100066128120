.registration-steps{
    padding: 15px 50px 50px 50px;
}
.register-header{
    border-bottom: 1px solid #ddd;
}
.registration-container{
    padding: 10px 30px 15px 30px;
}
.pright-20{
    padding-right: 20px !important;
}
.label-fixed-width{
    min-width: 300px;
} 
.doctor-widget p{
    margin-bottom: 2px !important;
}